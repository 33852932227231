import AutoComplete from '@tarekraafat/autocomplete.js'
import {BusinessEntryModeEventListener} from './business-entry-mode-event-listener'
import * as formElements from './form-elements'

export interface SearchParams {
	search?: string
	countryCode?: string
}

export interface SearchResponse {
	'@odata.context': string
	resultCount: number
	value: SearchResult[]
}

interface SearchResult {
	'@search.score': number
	CRMId: string
	Name: string
	AccountClassification: string
	id: string
	location: {
		address: string
		city: string
		postalCode: string
		state: string
		countryCode: string
		geometry: {
			type: string
			coordinates: [number, number]
			crs: {
				type: string
				properties: {
					name: string
				}
			}
		}
	}
}

let CountryCode = ''

function setCountryCode(locale: string): void {
	CountryCode =
		locale === '' || locale === 'testing'
			? 'US'
			: locale.toLocaleUpperCase()
}

function hideResultLabel(): void {
	const label = formElements.BusinessResultLabel()
	if (label) {
		label.innerHTML = ''
		label.style.display = 'none'
	}
}

function setSelectionLabel(selection: {
	Name: string
	location: {
		address: string
		city: string
		state: string
		postalCode: string
	}
}): void {
	const label = formElements.BusinessResultLabel()
	if (label) {
		label.innerHTML = `<span style="font-weight:bold;">${selection.Name}</span><br>${selection.location.address}, ${selection.location.city}, ${selection.location.state} ${selection.location.postalCode}`
		label.style.display = 'inline'
	}
}

function setNoResultsLabel(
	query: string,
	noResultText: string | undefined
): void {
	const label = formElements.BusinessResultLabel()
	if (label && noResultText) {
		label.innerHTML = `<span style="font-weight:bold;">'${noResultText} ${query}'</span>`
		label.style.display = 'inline-block'
	}
}

function setServiceErrorLabel(): void {
	const label = formElements.BusinessResultLabel()
	if (label) {
		label.innerHTML = `<span style="font-weight:bold;">The business search service is currently unavailable. Please try again later or enter your business manually.</span>`
		label.style.display = 'inline-block'
	}
}

function setBusinessSearchError(message: string): void {
	const label = formElements.BusinessSearchErrorLabel()
	if (label) {
		formElements.setErrorLabel(label, message)
	}
}

function clearBusinessSearchError(): void {
	const label = formElements.BusinessSearchErrorLabel()
	if (label) {
		formElements.setErrorLabel(label, '')
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- implicit is better here
function getData(query: string, noResultText: string | undefined) {
	const searchURL = '/api/business/search'

	const params = new URLSearchParams()
	params.set('search', query)
	params.set('countryCode', CountryCode)

	return fetch(`${searchURL}?${params.toString()}`)
		.then((response) => {
			return response.json()
		})
		.then((data) => {
			const searchResponse = data as SearchResponse
			const renderCount = searchResponse.resultCount
			if (CountryCode === 'US' || CountryCode === 'AU')
				return searchResponse.value
			if (renderCount > 1) {
				return []
			} else if (renderCount === 1) {
				return searchResponse.value
			} else if (renderCount === 0) {
				const zeroResults = noResultText
					? `${noResultText} "${query}"`
					: ''
				return [{zeroResults}]
			}
		})
		.catch((error) => {
			return [{error: JSON.stringify(error)}]
		})
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- implicit is better here
function createAutoCompleteConfig(noResultText: string) {
	return {
		selector: '.autocomplete-sep input[id^=gigya-textbox]',
		placeHolder: '', // This value will override by CDC. TODO: Get this value from Kontent.ai
		debounce: 500,
		data: {
			src: getData,
			cache: false,
		},
		searchEngine: (
			_query: string,
			record: {
				error?: string
				errorMessage?: string
				nullValueMessage?: string
				zeroResults?: string
				Name: string
				location: {
					address: string
					city: string
					state: string
					postalCode: string
				}
			}
		) => {
			if (record.error) return 'error'
			else if (record.errorMessage) return record.errorMessage
			else if (record.nullValueMessage) return record.nullValueMessage
			else if (record.zeroResults || record.zeroResults === '')
				return record.zeroResults
			return `<b>${record.Name}</b> <br>${record.location.address}, ${record.location.city}, ${record.location.state} ${record.location.postalCode}<br><br>`
		},
		resultsList: {
			maxResults: 50,
			element: (
				_list: unknown,
				data: {
					results: {
						value: {
							error?: {
								message: string
							}
						}
					}[]
					query: string
				}
			) => {
				if (!data.results.length) {
					setNoResultsLabel(data.query, noResultText)
				}

				if (
					data.results[0]?.value.error &&
					data.results[0].value.error.message === 'Failed to fetch'
				) {
					setServiceErrorLabel()
				}
			},
			noResults: true,
		},
		resultItem: {
			highlight: true,
		},
	}
}
interface BusinessData {
	crmId: string
	type: string
	name: string
	address1: string
	address2?: string
	city: string
	state: string
	zip: string
	country: string
}

class BusinessSearch {
	private searchDisabled = false

	private manualBusinessEntryToggled = false

	private businessData: BusinessData | null = null

	private noResultText: string | undefined = undefined
	constructor(noResultText?: string) {
		this.noResultText = noResultText
		const searchExists = formElements.BusinessSearchField() !== null
		const toggleExists = formElements.BusinessToggle() !== null
		this.searchDisabled = !(searchExists && toggleExists)
	}

	init(locale: string): void {
		if (this.searchDisabled) return

		const toggle = formElements.BusinessToggle()
		toggle?.addEventListener('change', (event) => {
			this.manualBusinessEntryToggled = !this.manualBusinessEntryToggled
			this.businessData = null
			clearBusinessSearchError()
			BusinessEntryModeEventListener(event)
		})

		hideResultLabel()
		this.setupAutoComplete(locale)
	}

	validate(): boolean {
		if (this.searchDisabled) return true

		const noBusinessSelected = this.businessData === null
		const searchModeEnabled = !this.manualBusinessEntryToggled
		if (noBusinessSelected && searchModeEnabled) {
			setBusinessSearchError('Please select or manually enter a business')
			return false
		}

		clearBusinessSearchError()
		return true
	}

	getBusinessData(): BusinessData | null {
		if (this.manualBusinessEntryToggled) {
			return {
				crmId: '',
				type: formElements.getTypeFieldValue() ?? '',
				name: formElements.getNameFieldValue() ?? '',
				address1: formElements.getAddress1FieldValue() ?? '',
				address2: formElements.getAddress2FieldValue(),
				city: formElements.getCityFieldValue() ?? '',
				state: formElements.getStateFieldValue() ?? '',
				zip: formElements.getZipFieldValue() ?? '',
				country: CountryCode,
			}
		}

		return this.businessData
	}

	setupAutoComplete(locale: string): void {
		if (this.searchDisabled) return
		setCountryCode(locale)
		const autoComplete = new AutoComplete({
			...createAutoCompleteConfig(this.noResultText || ''),
			data: {
				...createAutoCompleteConfig(this.noResultText || '').data,
				src: (query: string) => getData(query, this.noResultText),
			},
		})
		autoComplete.input.addEventListener('selection', (event) => {
			const feedback = event.detail
			autoComplete.input.blur()
			const selection = feedback.selection.value
			this.businessData = {
				crmId: selection.CRMId,
				type: selection.AccountClassification,
				name: selection.Name,
				address1: selection.location.address,
				address2: selection.location.address2,
				city: selection.location.city,
				state: selection.location.state,
				zip: selection.location.postalCode,
				country: selection.location.countryCode,
			}

			autoComplete.input.value = feedback.query
			setSelectionLabel(selection)
		})

		autoComplete.input.addEventListener('input', () => {
			hideResultLabel()
			clearBusinessSearchError()
			this.businessData = null
		})

		autoComplete.list.style[`overflow-y`] = 'scroll'
		autoComplete.list.style[`max-height`] = '200px'
		autoComplete.list.style.border = '1px solid rgba(33, 33, 33, 0.07)'
		autoComplete.list.classList.add('gigya-style-modern')
	}
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class -- Original design
class BusinessSearchFactory {
	static instance: BusinessSearch

	constructor() {
		throw new Error('Use BusinessSearchFactory.getInstance()')
	}

	static getInstance(noResultText?: string): BusinessSearch {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Original design
		if (!BusinessSearchFactory.instance) {
			BusinessSearchFactory.instance = new BusinessSearch(noResultText)
		}

		return BusinessSearchFactory.instance
	}
}

function setupBusinessSearch(locale: string, noResultText?: string): void {
	BusinessSearchFactory.getInstance(noResultText).init(locale)
}

function validateBusiness(): boolean {
	return BusinessSearchFactory.getInstance().validate()
}

function getBusinessData(): BusinessData | null {
	return BusinessSearchFactory.getInstance().getBusinessData()
}

export {setupBusinessSearch, validateBusiness, getBusinessData}
