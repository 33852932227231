import {ContentBlockTab, TabbedContentBlock} from '@elanco/component-library-v2'
import Image from 'next/future/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {brandColors} from '@elanco/component-library-v2/dist/Colors/brandColors'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '../../components/rich-text-renderer'
import {defaultMargins} from '../../styles'

type TabbedContentBlockFlexibleTabBlock = IContentItem<{
	title: Elements.TextElement
	enableScrollbar: Elements.MultipleChoiceElement
	tabBlocks: Elements.LinkedItemsElement
	tabColor: Elements.MultipleChoiceElement
}>

type TabbedContentBlockTabContentItem = IContentItem<{
	title: Elements.TextElement
	body: Elements.RichTextElement
	enableScrollbar: Elements.MultipleChoiceElement
	image: Elements.AssetsElement
	tabColor: Elements.MultipleChoiceElement
}>

export type TabbedContentBlockContentItem = IContentItem<{
	tabs: Elements.LinkedItemsElement<
		TabbedContentBlockTabContentItem | TabbedContentBlockFlexibleTabBlock
	>
	tabStyle: Elements.MultipleChoiceElement
}>

export const TabbedContentBlockBlock: Block<TabbedContentBlockContentItem> = ({
	block,
	BlockMapper,
	...context
}) => {
	return (
		<div className={defaultMargins}>
			<TabbedContentBlock
				tabColorStyles={brandColors}
				tabMobileView={block.elements.tabStyle[0]?.codename ?? 'stack'}
			>
				{block.elements.tabs.map((tab) => {
					if ('body' in tab.elements) {
						const standardTab =
							tab as Tersed<TabbedContentBlockTabContentItem>

						return (
							<ContentBlockTab
								color={{
									choice: standardTab.elements.tabColor[0]
										?.codename,
								}}
								disableOverflow
								key={standardTab.system.id}
								title={
									<span
										data-kontent-element-codename="title"
										data-kontent-item-id={
											standardTab.system.id
										}
									>
										{standardTab.elements.title}
									</span>
								}
							>
								{standardTab.elements.image[0] ? (
									<div
										className="md:flex"
										data-kontent-item-id={
											standardTab.system.id
										}
									>
										<div className="pb-6 md:w-1/2 md:pb-0 md:pr-8">
											<RichTextRenderer
												BlockMapper={BlockMapper}
												className="content break-words"
												data-kontent-element-codename="body"
												element={
													standardTab.elements.body
												}
												{...context}
											/>
										</div>
										<div className="pb-2 md:w-1/2 md:pl-8">
											<Image
												alt={
													standardTab.elements
														.image[0].description ??
													''
												}
												data-kontent-element-codename="image"
												height={
													standardTab.elements
														.image[0].height || 334
												}
												loader={loadImageFromKontentAI}
												src={
													standardTab.elements
														.image[0].url
												}
												width={
													standardTab.elements
														.image[0].width || 502
												}
											/>
										</div>
									</div>
								) : null}
							</ContentBlockTab>
						)
					}

					const flexibleTab =
						tab as Tersed<TabbedContentBlockFlexibleTabBlock>

					return (
						<ContentBlockTab
							color={{
								choice: tab.elements.tabColor[0]?.codename,
							}}
							key={tab.system.id}
							title={
								<span
									data-kontent-element-codename="title"
									data-kontent-item-id={tab.system.id}
								>
									{tab.elements.title}
								</span>
							}
						>
							<BlockMapper
								blocks={flexibleTab.elements.tabBlocks}
								{...context}
							/>
						</ContentBlockTab>
					)
				})}
			</TabbedContentBlock>
		</div>
	)
}
