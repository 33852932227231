import {TableHeader} from '@elanco/component-library-v2'
import type {
	ElementModels,
	Elements,
	IContentItem,
} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {Fragment, useState} from 'react'
import type {Block} from '@/_new-code/services/kontent-ai/types'

type ComparisonOptionContentItem = IContentItem<{
	comparisonFeature: Elements.TextElement
	icon: Elements.AssetsElement
}>

type ComparisonProductColumnContentItem = IContentItem<{
	companyLogo: Elements.AssetsElement
	comparisonLabel: Elements.TextElement
	row1Tick: Elements.MultipleChoiceElement
	row2Tick: Elements.MultipleChoiceElement
	row3Tick: Elements.MultipleChoiceElement
	row4Tick: Elements.MultipleChoiceElement
	row5Tick: Elements.MultipleChoiceElement
	row6Tick: Elements.MultipleChoiceElement
	row7Tick: Elements.MultipleChoiceElement
	row8Tick: Elements.MultipleChoiceElement
	row9Tick: Elements.MultipleChoiceElement
	row10Tick: Elements.MultipleChoiceElement
	row11Tick: Elements.MultipleChoiceElement
	row12Tick: Elements.MultipleChoiceElement
	row13Tick: Elements.MultipleChoiceElement
	row14Tick: Elements.MultipleChoiceElement
	row15Tick: Elements.MultipleChoiceElement
}>

export type ComparisonTableContentItem = IContentItem<{
	comparisonRows: Elements.LinkedItemsElement<ComparisonOptionContentItem>
	comparisonColumns: Elements.LinkedItemsElement<ComparisonProductColumnContentItem>
	tabTitle: Elements.TextElement
}>

interface CircleIconProps {
	circleClasses: string
	iconType: 'Cross' | 'Tick'
}

const CircleIcon = ({
	circleClasses,
	iconType,
}: CircleIconProps): JSX.Element => (
	<div
		className={clsx(
			`h-5 w-5 overflow-hidden rounded-full md:h-6 md:w-6`,
			circleClasses
		)}
	>
		<span className="flex h-full w-full flex-col items-center justify-center">
			{iconType === 'Tick' && (
				<svg
					aria-hidden="true"
					className="h-6 w-6 fill-current text-white"
					focusable="false"
					viewBox="0 0 188 188"
				>
					<path d="M148.49,61.73l-7.94-7.94a5.62,5.62,0,0,0-7.94,0L80,106.43,55.39,81.85a5.62,5.62,0,0,0-7.94,0l-7.94,7.94a5.62,5.62,0,0,0,0,7.94L76,134.21a5.62,5.62,0,0,0,7.94,0l64.55-64.55A5.61,5.61,0,0,0,148.49,61.73Z" />
				</svg>
			)}
			{iconType === 'Cross' && (
				<svg
					className="h-3 w-3 fill-current text-white"
					height="20"
					viewBox="0 0 15.7 15.7"
					width="20"
				>
					<path
						d="M14.3,0.3l1.1,1.1c0.4,0.4,0.4,1,0,1.4L2.8,15.4c-0.4,0.4-1,0.4-1.4,0l-1.1-1.1c-0.4-0.4-0.4-1,0-1.4
				L12.9,0.3C13.3-0.1,13.9-0.1,14.3,0.3z"
					/>
					<path
						d="M15.4,14.3l-1.1,1.1c-0.4,0.4-1,0.4-1.4,0L0.3,2.8c-0.4-0.4-0.4-1,0-1.4l1.1-1.1c0.4-0.4,1-0.4,1.4,0
				l12.6,12.6C15.8,13.3,15.8,13.9,15.4,14.3z"
					/>
				</svg>
			)}
		</span>
	</div>
)

export const ComparisonTableBlock: Block<ComparisonTableContentItem> = ({
	block,
}) => {
	const columns = [
		{
			label: '',
			id: '',
			content: null,
		},
		...block.elements.comparisonColumns.map(
			({
				elements: {comparisonLabel: label, companyLogo},
				system: {id: kItemId},
			}) => ({
				label,
				id: kItemId,
				content: (
					<TableHeader
						centered
						column
						iconAlt={label || ''}
						iconUrl={companyLogo[0]?.url || ''}
						text={
							<span className="block md:hidden">
								{label || ''}
							</span>
						}
					/>
				),
			})
		),
	]

	const rows = block.elements.comparisonRows.map(
		(
			{elements: {comparisonFeature: label, icon}, system: {id: kItemId}},
			rowIndex
		) => ({
			header: {
				label,
				id: kItemId,
				content: (
					<TableHeader
						iconAlt={label || ''}
						iconUrl={icon[0]?.url ? icon[0]?.url : ''}
						text={label || ''}
					/>
				),
			},
			row: block.elements.comparisonColumns.flatMap((column) => {
				const isChecked =
					(
						column.elements[
							`row${
								rowIndex + 1
							}Tick` as keyof typeof column.elements
						] as ElementModels.MultipleChoiceOption[]
					)[0]?.codename === 'yes'
				return {
					columnId: column.system.id,
					rowId: kItemId,
					content: isChecked ? (
						<CircleIcon
							circleClasses="mx-auto bg-blue-500"
							iconType="Tick"
						/>
					) : (
						<CircleIcon
							circleClasses="mx-auto bg-red-500"
							iconType="Cross"
						/>
					),
				}
			}),
		})
	)

	const [dropDownValue, setDropDownValue] = useState<string>(
		columns[2]?.id ?? ''
	)

	const dropdownItems = columns.filter(
		(col) => col.label && columns[1]?.id !== col.id
	)

	const mobileView = (
		<table className="w-full shadow-primary md:hidden">
			<thead>
				<tr>
					<td className="border-r !border-solid border-gray-600 p-6 pb-0">
						{columns[0]?.content}
					</td>
					<th
						className="border-r !border-solid border-gray-600 p-3"
						scope="col"
					>
						{columns[1]?.content}
					</th>
					<th className="border-r border-gray-600 p-3" scope="col">
						{dropdownItems.length > 0 ? (
							<label htmlFor="products-dropdown">
								<select
									className="min-w-110 p-2.5 text-sm"
									id="products-dropdown"
									name="products-dropdown"
									onChange={(e) => {
										setDropDownValue(e.target.value)
									}}
									style={{
										minWidth: '110px !important', // TODO: Remove style attribute once global styles removed from DS
									}}
								>
									{dropdownItems.map(({label, id}) => (
										<option key={id} value={id}>
											{label}
										</option>
									))}
								</select>
							</label>
						) : (
							<th
								className="border-r border-gray-600 p-3"
								scope="col"
							>
								{columns[2]?.content}
							</th>
						)}
					</th>
				</tr>
			</thead>
			<tbody>
				{rows.map(({header, row}) => (
					<tr
						className="odd:bg-gray-100 even:bg-transparent"
						key={`${header.id}-${row[0]?.columnId}`}
					>
						<th
							className="border-r !border-solid border-gray-600 p-3"
							scope="row"
						>
							{header.content}
						</th>
						<td className="border-r border-gray-600 p-7">
							{row[0]?.content || <span />}
						</td>
						<td className="border-r border-gray-600 p-3">
							{row.find((item) => item.columnId === dropDownValue)
								?.content || <span />}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)

	const desktopView = (
		<table className="hidden w-full rounded-lg shadow-primary md:!table">
			<thead>
				<tr>
					{columns.map((column) => (
						<Fragment key={column.id}>
							{column.content ? (
								<th
									className="border-r border-gray-600 p-6 pb-0 md:pb-4"
									scope="col"
								>
									{column.content}
								</th>
							) : (
								<td className="border-r !border-solid border-gray-600" />
							)}
						</Fragment>
					))}
				</tr>
			</thead>
			<tbody>
				{rows.map(({header, row}) => (
					<tr
						className="odd:bg-gray-100 even:bg-transparent"
						key={header.id}
					>
						<th
							className="border-r !border-solid border-gray-600 p-7"
							scope="row"
						>
							{header.content}
						</th>
						{row.map((item) => (
							<td
								className="border-r border-gray-600 p-7"
								key={`${item.columnId}-${item.rowId}`}
							>
								{item.content}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)

	return (
		<div>
			{mobileView}
			{desktopView}
		</div>
	)
}
