import type {ReactElement} from 'react'
import {useEffect, useRef, useState} from 'react'
import {EllipseButton} from '@elanco/component-library-v2'
import {Loader} from '@googlemaps/js-api-loader'
import type {LatLng} from '@/_new-code/products/disease-map/parasite-tracker/parasite-tracker-module'
import {env} from '@/utils/env/client.mjs'

export type PlaceType =
	| 'administrative_area_level_1'
	| 'administrative_area_level_2'
	| 'country'

export type SearchHandler = (
	data: LatLng,
	placeType: PlaceType
) => Promise<void>

interface SearchDefaultProps {
	onSearchApi: SearchHandler
	placeholderText?: string
	onError: () => void
	onLoading: (value: boolean) => void
	theme?: string
}

export function Search({
	onSearchApi,
	placeholderText = 'Search',
	onError,
	onLoading,
	theme,
}: SearchDefaultProps): ReactElement {
	const autoCompleteRef = useRef<HTMLInputElement>(null)
	const [autoComplete, setAutoComplete] =
		useState<google.maps.places.Autocomplete | null>(null)
	const [searchedValue, setSearchedValue] = useState<string>('')
	const icon = {
		Location: {
			class: 'location',
			icon: (
				<svg
					baseProfile="tiny"
					fill={theme ?? 'currentColor'}
					height="24px"
					stroke={theme ?? 'currentColor'}
					strokeWidth="0"
					version="1.2"
					viewBox="0 0 24 24"
					width="24px"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12 5c1.609 0 3.12.614 4.254 1.73 1.126 1.107 1.746 2.579 1.746 4.14s-.62 3.03-1.745 4.139l-4.255 4.184-4.254-4.186c-1.125-1.107-1.745-2.576-1.745-4.139s.62-3.032 1.745-4.141c1.135-1.113 2.647-1.727 4.254-1.727m0-2c-2.047 0-4.096.768-5.657 2.305-3.124 3.074-3.124 8.057 0 11.131l5.657 5.563 5.657-5.565c3.124-3.072 3.124-8.056 0-11.129-1.561-1.537-3.609-2.305-5.657-2.305zM12 8.499c.668 0 1.296.26 1.768.731.976.976.976 2.562 0 3.537-.473.472-1.1.731-1.768.731s-1.295-.26-1.768-.731c-.976-.976-.976-2.562 0-3.537.473-.471 1.101-.731 1.768-.731m0-1c-.896 0-1.792.342-2.475 1.024-1.367 1.367-1.367 3.584 0 4.951.684.684 1.578 1.024 2.475 1.024s1.792-.342 2.475-1.024c1.366-1.367 1.366-3.584 0-4.951-.683-.683-1.579-1.024-2.475-1.024z"
						style={{fill: theme ?? 'currentcolor'}}
					/>
				</svg>
			),
		},
	}
	useEffect(() => {
		const loader = new Loader({
			apiKey: env.NEXT_PUBLIC_MAP_API_KEY,
			version: 'weekly',
		})
		void loader.importLibrary('places').then(() => {
			if (autoCompleteRef.current) {
				const newAutoComplete = new google.maps.places.Autocomplete(
					autoCompleteRef.current,
					{
						types: ['geocode'],
						componentRestrictions: {country: ['US']},
						fields: [
							'formatted_address',
							'geometry',
							'name',
							'address_components',
						],
					}
				)
				setAutoComplete(newAutoComplete)
				newAutoComplete.addListener('place_changed', () => {
					const addressObject = newAutoComplete.getPlace()
					if (!addressObject.geometry?.location) {
						return
					}

					const {
						formatted_address: queryPlace,
						address_components: addressComponents,
					} = addressObject

					onLoading(true)
					void onSearchApi(
						{
							lat: addressObject.geometry.location.lat(),
							lng: addressObject.geometry.location.lng(),
						},
						getAdminLevel(addressComponents)
					)
					setSearchedValue(queryPlace || '')
					onLoading(false)
				})
			}
		})
	}, [onError, onLoading, onSearchApi])

	const getAdminLevel = (
		addressComponents: google.maps.GeocoderAddressComponent[] | undefined
	): PlaceType => {
		if (
			addressComponents?.some((obj) =>
				obj.types.includes('administrative_area_level_2')
			)
		)
			return 'administrative_area_level_2'
		if (
			addressComponents?.some((obj) =>
				obj.types.includes('administrative_area_level_1')
			)
		)
			return 'administrative_area_level_1'
		return 'country'
	}
	const getAddressFromGeocode = (lat: number, lng: number): void => {
		const geocoder = new window.google.maps.Geocoder()
		const latlng = {
			lat,
			lng,
		}
		geocoder
			.geocode({location: latlng})
			.then(({results}) => {
				if (results[0]) {
					setSearchedValue(results[0].formatted_address)
				}
			})
			.catch(() => {
				onError()
			})
	}

	const handleGeoLocation = (): void => {
		onLoading(true)
		navigator.geolocation.getCurrentPosition((position) => {
			void onSearchApi(
				{
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				},
				'administrative_area_level_1'
			)
			getAddressFromGeocode(
				position.coords.latitude,
				position.coords.longitude
			)
		})
		onLoading(false)
	}
	return (
		<div className="absolute z-40 flex w-full max-w-md items-center pl-2 md:right-5 md:top-2 ">
			<div className="relative mt-3 w-3/5 sm:w-10/12 md:mt-0">
				<label htmlFor="parasite-search-input">
					<span className="visuallyhidden">Search</span>
					<input
						className="parasite-search-input mb-0 p-3 text-black"
						id="parasite-search-input"
						onChange={(e) => {
							if (autoComplete) setSearchedValue(e.target.value)
						}}
						placeholder={placeholderText}
						ref={autoCompleteRef}
						type="text"
						value={searchedValue}
					/>
				</label>
			</div>
			<EllipseButton
				as="button"
				className="z-50 m-2 my-auto"
				icon={icon.Location}
				iconSize="w-4 h-4"
				noIconAnimation
				onClick={handleGeoLocation}
				size="w-7 h-7"
				variant="tertiary"
			>
				Search by current location
			</EllipseButton>
		</div>
	)
}
