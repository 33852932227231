import {Wistia as CWistia} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import Image from 'next/future/image'
import {clsx} from 'clsx'
import {useTrustArcCookieValue} from '@/utils/analytics'
import type {BlockWithClassName} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {FallbackBlock} from '../fallback-block'
import {spacing} from '../../../styles'

export type WistiaContentItem = IContentItem<{
	id: Elements.TextElement
	alt: Elements.TextElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	cookieFallbackImage: Elements.AssetsElement
	tabTitle: Elements.TextElement
	videoDescription: Elements.RichTextElement
	textAlignment: Elements.MultipleChoiceElement
	wistiaPodcast: Elements.MultipleChoiceElement
}>

export const WistiaBlock: BlockWithClassName<WistiaContentItem> = ({
	block,
	className,
	...context
}) => {
	const spacingChoice =
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		]
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, spacingChoice may be null
	const componentSpacing = !spacingChoice ? spacing.default : spacingChoice
	const trustArcCookie = useTrustArcCookieValue()
	const alignmentOptions: Record<string, string> = {
		left: 'text-left',
		center: 'text-center',
		right: 'text-right',
		justify: 'text-justify',
	}
	const alignment =
		alignmentOptions[block.elements.textAlignment[0]?.codename ?? 'left']

	return (
		<div className={componentSpacing}>
			{trustArcCookie === '1:' || trustArcCookie === '0:' ? (
				<div className="flex justify-center">
					{block.elements.cookieFallbackImage[0] ? (
						<Image
							alt={
								block.elements.cookieFallbackImage[0]
									.description ?? undefined
							}
							fill
							src={block.elements.cookieFallbackImage[0].url}
						/>
					) : (
						<FallbackBlock />
					)}
				</div>
			) : (
				<div
					className={clsx(
						componentSpacing === spacing.none
							? 'mb-4 w-full pb-6 lg:mb-10 lg:pb-10'
							: 'container-wide mb-4 pb-6',
						'my-0 flex flex-col py-0',
						alignment
					)}
				>
					{block.elements.videoDescription.value !==
						'<p><br></p>' && (
						<RichTextRenderer
							element={block.elements.videoDescription}
							{...context}
						/>
					)}
					<CWistia
						alt={block.elements.alt}
						className={className}
						componentSpacing={componentSpacing}
						id={block.elements.id}
						spacing={spacing}
						wistiaPodcast={{
							choice:
								block.elements.wistiaPodcast[0]?.codename ||
								'video',
						}}
					/>
				</div>
			)}
		</div>
	)
}
